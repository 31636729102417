import store from '@/store'
import OSS from "ali-oss"
import md5 from 'js-md5'
import { getStstoken, saveFile, metarialFile } from '@/api/upload'

export function OSSUpload(file, folder) {
	return new Promise((resolve, reject) => {
		getStstoken({ uuid: store.getters.uuid }).then(response => {
			let suffix = file.name.toString().split(".")[file.name.toString().split(".").length - 1];
			let filename = md5(file.name + file.type + file.size + Date.now());
			let folderPath = response.data.Path + folder + '/' + filename + '.' + suffix;
			const client = new OSS({
				region: response.data.Region,
				accessKeyId: response.data.AccessKeyId,
				accessKeySecret: response.data.AccessKeySecret,
				stsToken: response.data.SecurityToken,
				bucket: response.data.bucket
			})
			client.put(folderPath, file).then(function (res) {
				resolve(res)
			}).catch(function (err) {
				reject(err)
			})
		})
	})
}

export async function normalOSSUpload(file, folder) {
	const result = await OSSUpload(file, folder)
	return new Promise((resolve, reject) => {
		if (result.url) {
			const file_ext = file.name.toString().split(".")[file.name.toString().split(".").length - 1];
			const params = {
				file_ext: file_ext,
				file_mime: file.type,
				file_name: file.name,
				file_path: result.url,
				file_szie: file.size,
				type: folder
			}
			saveFile(params).then(response => {
				resolve(response)
			}).catch(function (err) {
				reject(err)
			})
		} else {
			reject(result)
		}
	})
}

export async function materialOSSUpload(pid = 0, type = 0, file, folder) {
	const result = await OSSUpload(file, folder)
	return new Promise((resolve, reject) => {
		if (result.url) {
			var img = new Image();
			img.src = result.url;
			img.onload = () => {
				let file_ext = file.name.toString().split(".")[file.name.toString().split(".").length - 1];
				let params = {
					ext: file_ext,
					mime: file.type,
					name: file.name,
					parent_id: pid,
					url: result.url,
					size: file.size,
					type: type,
					high: img.height,
					wide: img.width
				}
				metarialFile(params).then(response => {
					resolve(response)
				}).catch(function (err) {
					reject(err)
				})
			}
		} else {
			reject(result)
		}
	})
}


export function EditorOSSUpload(file, folder) {
	return new Promise((resolve, reject) => {
		const params = {
			uuid: store.getters.uuid,
			folder: folder
		}
		getStstoken(params).then(response => {
			let suffix = 'png'
			const imagename = file.name ? file.name : 'images'
			if (file.name) {
				suffix = file.name.toString().split('.')[file.name.toString().split('.').length - 1]
			} else {
				switch (file.type) {
					case 'image/png':
						suffix = 'png'
						break
					case 'image/jpg':
						suffix = 'jpg'
						break
					case 'image/jpeg':
						suffix = 'jpg'
						break
					case 'image/gif':
						suffix = 'gif'
						break
					case 'video/mp3':
						suffix = 'mp3'
						break
					case 'video/mp4':
						suffix = 'mp4'
						break
					default: suffix = 'png'
				}
			}
			const filename = md5(imagename + file.type + file.size + Date.now())
			const folderPath = response.data.Path + '/' + filename + '.' + suffix
			const client = new OSS({
				region: response.data.Region,
				accessKeyId: response.data.AccessKeyId,
				accessKeySecret: response.data.AccessKeySecret,
				stsToken: response.data.SecurityToken,
				bucket: response.data.bucket
			})
			client.put(folderPath, file).then(function (res) {
				resolve(res)
			}).catch(function (err) {
				reject(err)
			})
		})
	})
}

