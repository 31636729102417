import request from '@/utils/request'
import { param2Obj } from '@/utils'

export function getStstoken(data) {
  return request({
    url: '/seller/common/ststoken',
    method: 'post',
    data: param2Obj(data)
  })
}

export function saveFile(data) {
  return request({
    url: '/seller/common/save/file',
    method: 'post',
    data: param2Obj(data)
  })
}

export function metarialFile(data) {
  return request({
    url: '/seller/metarial/add',
    method: 'post',
    data: param2Obj(data)
  })
}
